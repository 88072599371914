<template>
  <div class="reg-notification">
    <h3 class="h3-notification-pageTitle">
      {{ $t('your') + $t('all') + $t('bulletin') + $t('notify') }}
    </h3>
    <!-- <div v-for="(item, index) in UserNotification.data" :key="index" class="row are-notification"> -->

    <div class="row are-notification">
      <div class="col-12 col-md-9 blk-notification-title">
        <template>
          <a href="/dojo/evaluations/" class="lnk-notification-title">課後評鑑通知</a>
        </template>
      </div>
      <div class="col-12 col-md-3 blk-notification-date">Nov 22,2021</div>
    </div>

    <div class="row are-notification">
      <div class="col-12 col-md-9 blk-notification-title">
        <template>
          <a href="javascript:;" class="lnk-notification-title">
            <i class="fa fa-sign-in fa-lg" />進教室通知
          </a>
        </template>
      </div>
      <div class="col-12 col-md-3 blk-notification-date">Nov 22,2021</div>
    </div>

    <div class="row are-notification">
      <div class="col-12 col-md-9 blk-notification-title">
        <!--課後評鑑通知連結-->
        <!-- <template>
                    <a href="/dojo/evaluations/" class="lnk-notification-title">課後評鑑通知</a>
                </template> -->
        <!--進教室通知連結-->
        <!-- <template>
                    <a href="javascript:;" class="lnk-notification-title">
                        <i class="fa fa-sign-in fa-lg"></i>進教室通知
                    </a>
                </template> -->
        <!--公告通知連結-->
        <template>
          <!-- <router-link :to="{name: 'BulletinView', params: {type: item.type , id: item.id} }" class="lnk-notification-title">
                        <span v-if="item.type == 'bulletin'" class="txt-notification-category"> [ {{ $t('bulletin') }} ]</span>
                        {{ item.title }}
                    </router-link> -->
          <span class="txt-notification-category">[{{ $t('bulletin') }}]</span> 公告標題
        </template>
      </div>

      <div class="col-12 col-md-3 blk-notification-date">Nov 22,2021</div>
    </div>
    <b-pagination align="center" total-rows="5" :per-page="5" />
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'VipNotification',

  components: {},

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {},
};
</script>

<style lang="scss">
@import '@/styles/vips/notification';
</style>
